/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IWWCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './ww-character-dynamic.scss';
import { Accordion, Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faBurst,
  faChartLine,
  faCircleInfo,
  faCube,
  faDiagramProject,
  faPeopleGroup,
  faPersonRunning,
  faSquare,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { WWStats } from '../modules/ww/common/components/ww-stats';
import { Range, getTrackBackground } from 'react-range';
import Switch from 'react-switch';
import { WWStat } from '../modules/ww/common/components/ww-stat';
import { YoutubeEmbed } from '../modules/common/components/youtube-embed';
import { CategoryCard } from '../modules/cs/main/components/category-card';
import { WWSet } from '../modules/ww/common/components/ww-set';
import { WWWeaponEntry } from '../modules/ww/weapons/ww-weapon';
import { WWSkillRelease } from '../modules/ww/skills/ww-skill-release';
import { WWDmgDistribution } from '../modules/ww/profile/ww-dmg';
import { RatingBox } from '../modules/common/components/rating-box';
import { WWCharacterCalcs } from '../modules/ww/profile/ww-calcs';
import { WWWeaponNotes } from '../modules/ww/weapons/ww-weapon-notes';
import { WWTeams } from '../modules/ww/profile/ww-teams';
import { WWMainEchoesProfile } from '../modules/ww/profile/ww-main-echoes';
import { WWSkillPrioProfile } from '../modules/ww/profile/ww-skill-prio';
import { WWEchoAlt } from '../modules/ww/profile/ww-echo-alt';
import { WWEchoStatsComparison } from '../modules/ww/profile/ww-echo-stats';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IWWCharacterNodes {
  nodes: IWWCharacter[];
}

interface IWWCharacterEntry {
  currentUnit: IWWCharacterNodes;
}

interface IProps {
  data: IWWCharacterEntry;
}

const WWCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');
  const [currentTabSkills, setCurrentTabSkills] = useState('Active');
  const [currentLevel, setCurrentLevel] = useState([90]);
  const [color, setColor] = useState('#979797');
  const [statsMode, setStatsMode] = useState(false);

  useEffect(() => {
    if (character.element === 'Spectro') {
      setColor('#f8e56c');
    } else if (character.element === 'Havoc') {
      setColor('#e649a6');
    } else if (character.element === 'Glacio') {
      setColor('#41aefb');
    } else if (character.element === 'Aero') {
      setColor('#55ffb5');
    } else if (character.element === 'Fusion') {
      setColor('#f0744e');
    } else if (character.element === 'Electro') {
      setColor('#b46bff');
    }
  }, []);

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-ww'}
      game="ww"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.element} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={6} xxl={character.videos ? 6 : 9}>
            <div className={`content-header ${character.element}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}★
                  </strong>{' '}
                  rarity character from the{' '}
                  {character.element === 'Aero' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_aero.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  {character.element === 'Electro' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_electro.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  {character.element === 'Fusion' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_fusion.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  {character.element === 'Glacio' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_glacio.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  {character.element === 'Havoc' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_havoc.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  {character.element === 'Spectro' && (
                    <>
                      <StaticImage
                        src="../images/ww/icons/element_spectro.png"
                        alt={character.element}
                      />
                    </>
                  )}
                  <strong className={`${character.element}`}>
                    {character.element}
                  </strong>{' '}
                  element who uses the{' '}
                  <strong className={`${character.weapon}`}>
                    {character.weapon}
                  </strong>{' '}
                  type weapon.
                </h2>
                {character.introduction ? (
                  <>{renderRichText(character.introduction, options)}</>
                ) : (
                  ''
                )}
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
          <Col xs={12} xl={6} xxl={character.videos ? 6 : 3}>
            <div className="video-promo">
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Video guide
              </div>
              <div className="video-guide">
                {character.videos && character.slug != 'aalto' ? (
                  <>
                    <div>
                      <p>
                        <strong className={`${character.element}`}>
                          {character.name}
                        </strong>{' '}
                        has at least one video guide available, so if you prefer
                        this sort of medium to learn more about a character,
                        check it out:
                      </p>
                    </div>
                    <Row xs={1} xl={2} className="video-row">
                      {character.videos.map((videoLink, idx) => {
                        return (
                          <>
                            {idx === 0 && (
                              <Col>
                                <YoutubeEmbed embedId={videoLink.video} />
                              </Col>
                            )}
                            {character.videos.length > 1 && idx === 1 && (
                              <Col className="mobile">
                                <YoutubeEmbed embedId={videoLink.video} />
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                    {character.slug === 'jinhsi' && (
                      <p className="smaller">
                        The calculations shown in the video are team-based, so
                        some of the findings there will differ from what's
                        available on this page.
                      </p>
                    )}
                  </>
                ) : (
                  <p>This character has no video guide yet.</p>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="last-updated">
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              profile has been last updated on{' '}
              <strong>{character.updatedAt}</strong>.
            </p>
          </div>
        </div>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.element}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Teams' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Teams')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
            </div>
            <p>Gameplay and teams</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faChartLine} width="18" />
            </div>
            <p>Calculations</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.upcoming != true ? (
            <>
              <div className="tabs-skills">
                <div
                  className={`single-tab ${character.element} ${
                    currentTabSkills === 'Active' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentTabSkills('Active')}
                >
                  <FontAwesomeIcon icon={faCube} width="18" /> Active skills
                </div>
                <div
                  className={`single-tab ${character.element} ${
                    currentTabSkills === 'Passive' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentTabSkills('Passive')}
                >
                  <FontAwesomeIcon icon={faBurst} width="18" /> Passive skills
                </div>
                <div
                  className={`single-tab ${character.element} ${
                    currentTabSkills === 'Concerto' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentTabSkills('Concerto')}
                >
                  <FontAwesomeIcon icon={faPersonRunning} width="18" /> Concerto
                  skills
                </div>
              </div>
              <>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Active' ? 'active' : ''
                  }`}
                >
                  <div className={`skills ${character.element}`}>
                    <Row xs={1} xl={1} xxl={2}>
                      <Col>
                        {character.skills.map((skill) => {
                          return (
                            <>
                              {skill.type === 'Basic Attack' && (
                                <>
                                  <WWSkillRelease
                                    char={character.slug}
                                    skill={skill}
                                    element={character.element}
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </Col>
                      <Col className="double-skills">
                        {character.skills.map((skill) => {
                          return (
                            <>
                              {skill.type === 'Resonance Skill' && (
                                <>
                                  <WWSkillRelease
                                    char={character.slug}
                                    skill={skill}
                                    element={character.element}
                                  />
                                </>
                              )}
                              {skill.type === 'Resonance Liberation' && (
                                <>
                                  <WWSkillRelease
                                    char={character.slug}
                                    skill={skill}
                                    element={character.element}
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Passive' ? 'active' : ''
                  }`}
                >
                  <div className={`skills ${character.element}`}>
                    <Row xs={1} xl={1} xxl={1}>
                      <Col>
                        {character.skills.map((skill) => {
                          return (
                            <>
                              {skill.type === 'Forte Circuit' && (
                                <>
                                  <WWSkillRelease
                                    char={character.slug}
                                    skill={skill}
                                    element={character.element}
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row xs={1} xl={1} xxl={2}>
                      {character.skills.map((skill) => {
                        return (
                          <>
                            {skill.type === 'Inherent Skill' && (
                              <Col>
                                <WWSkillRelease
                                  char={character.slug}
                                  skill={skill}
                                  element={character.element}
                                />
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
                <div
                  className={`tab-inside ${
                    currentTabSkills === 'Concerto' ? 'active' : ''
                  }`}
                >
                  <div className={`skills ${character.element}`}>
                    <Row xs={1} xl={1} xxl={2}>
                      {character.skills.map((skill) => {
                        return (
                          <>
                            {skill.category === 'Transition' && (
                              <Col>
                                <WWSkillRelease
                                  char={character.slug}
                                  skill={skill}
                                  element={character.element}
                                />
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Resonance Chain
            (Dupes)
          </div>
          {character.upcoming != true ? (
            <>
              {character.dupes ? (
                <>
                  <div className="skills dupes">
                    <Row xs={1} xxl={2}>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S1
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 1</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe1, options)}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S2
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 2</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe2, options)}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S3
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 3</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe3, options)}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S4
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 4</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe4, options)}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S5
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 5</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe5, options)}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box">
                          <div className="skill-header">
                            <div className={`skill-icon ${character.element}`}>
                              S6
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">Sequence Node 6</p>
                            </div>
                          </div>
                          <div
                            className={`skill-with-coloring dupe ${character.element}`}
                          >
                            {renderRichText(character.dupes.dupe6, options)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div className="info-box">
                  <p>
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    <strong>dupes information aren't available yet.</strong>{' '}
                    They will be added soon!
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>dupes information aren't available yet.</strong> They
                  will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Minor Fortes (Total)
          </div>
          {character.attributeBonus ? (
            <>
              <div className="smaller-traces">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box trace">
                      <WWStat stat={character.attributeBonus.trace1.stat} />{' '}
                      <span className="value">
                        +{character.attributeBonus.trace1.value}%
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <WWStat stat={character.attributeBonus.trace2.stat} />{' '}
                      <span className="value">
                        +{character.attributeBonus.trace2.value}%
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>Minor Fortes information aren't available yet.</strong>{' '}
                They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats
          </div>
          {character.upcoming != true ? (
            <>
              <div className="stats-header">
                <div className="info">
                  <div className="custom-switch">
                    <Switch
                      checked={statsMode}
                      onChange={() => setStatsMode(!statsMode)}
                      onColor={color}
                      offColor="#484950"
                      className="switch"
                    />
                    Include minor fortes
                  </div>
                </div>
                <div className="level-changer">
                  <span className="current-level">
                    Lv.{' '}
                    <span className={`level ${character.element}`}>
                      {currentLevel}
                    </span>
                  </span>
                  <div className={`level-slider `}>
                    <Range
                      step={1}
                      min={1}
                      max={90}
                      values={currentLevel}
                      onChange={(values) => setCurrentLevel(values)}
                      renderTrack={({ props, children }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: '5px',
                              width: '100%',
                              borderRadius: '0px',
                              background: getTrackBackground({
                                values: currentLevel,
                                colors: [color, '#484950'],
                                min: 1,
                                max: 90
                              }),
                              alignSelf: 'center'
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '20px',
                            width: '20px',
                            borderRadius: '0px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            style={{
                              height: '14px',
                              width: '5px',
                              backgroundColor: isDragged ? color : '#484950'
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <WWStats
                char_id={character.devId}
                currentLevel={currentLevel}
                element={character.element}
                bonus={statsMode}
              />
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>stats aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Upgrade Materials
          </div>
          {character.ascensionMaterials ? (
            <>
              <div className="upgrade-materials">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <h5>Character Ascension (total)</h5>
                      <ul>
                        {character.ascensionMaterials.common.map(
                          (material, idx) => {
                            return (
                              <li key={idx}>
                                <strong>{material.number_char}x</strong>{' '}
                                <strong className={`rarity-${idx + 2}`}>
                                  {material.name}
                                </strong>
                              </li>
                            );
                          }
                        )}
                        <li>
                          <strong>
                            {character.ascensionMaterials.breakthrough.number}x
                          </strong>{' '}
                          <strong className={`rarity-4`}>
                            {character.ascensionMaterials.breakthrough.name}
                          </strong>
                        </li>
                        <li>
                          <strong>
                            {character.ascensionMaterials.ascension.number}x
                          </strong>{' '}
                          <strong className={`rarity-1`}>
                            {character.ascensionMaterials.ascension.name}
                          </strong>
                        </li>
                        <li>
                          <strong>170 000</strong>{' '}
                          <strong className={`rarity-1`}>Shell Credits</strong>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <h5>Skill Upgrades (total)</h5>
                      <ul>
                        {character.ascensionMaterials.common.map(
                          (material, idx) => {
                            return (
                              <li key={idx}>
                                <strong>{material.number_skill}x</strong>{' '}
                                <strong className={`rarity-${idx + 2}`}>
                                  {material.name}
                                </strong>
                              </li>
                            );
                          }
                        )}
                        {character.ascensionMaterials.skill_other.map(
                          (material, idx) => {
                            return (
                              <li key={idx}>
                                <strong>{material.number}x</strong>{' '}
                                <strong className={`rarity-${idx + 2}`}>
                                  {material.name}
                                </strong>
                              </li>
                            );
                          }
                        )}
                        <li>
                          <strong>
                            {character.ascensionMaterials.skill.number}x
                          </strong>{' '}
                          <strong className={`rarity-4`}>
                            {character.ascensionMaterials.skill.name}
                          </strong>
                        </li>
                        <li>
                          <strong>2 030 000</strong>{' '}
                          <strong className={`rarity-1`}>Shell Credits</strong>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <p>
                  While the source of the materials can be quickly found in the
                  game, when it comes{' '}
                  <strong className={`rarity-1`}>
                    {character.ascensionMaterials.ascension.name}
                  </strong>{' '}
                  you will have to gather them from the map. Check our{' '}
                  <strong>Ascension material map</strong> guide to find them
                  quickly:
                </p>
                <Row
                  xs={1}
                  md={2}
                  lg={2}
                  xxl={3}
                  className="g-3 category-cards-container"
                >
                  <CategoryCard
                    title="Ascension material maps"
                    link="/wuthering-waves/guides/ascension-materials"
                    image={
                      <StaticImage
                        src="../images/ww/categories/category_asc.jpg"
                        alt="Ascension material maps"
                      />
                    }
                  />
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>
                    upgrade material information aren't available yet.
                  </strong>{' '}
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.voiceActors.en ? character.voiceActors.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.voiceActors.jpn ? character.voiceActors.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.voiceActors.cn ? character.voiceActors.cn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.voiceActors.kr ? character.voiceActors.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Mini-review
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>mini-review isn't available yet.</strong> It will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element} `}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis ">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <div className="info-box tier">
            <p>
              This tier list assesses characters based on their combat potential
              in{' '}
              <strong>
                Wuthering Waves current most demanding content Tower of
                Adversity (ToA)
              </strong>
              . ToA is a mode similar to Genshin Impact’s Spiral Abyss or Honkai
              Star Rail’s Memory of Chaos and requires multiple teams, strong
              Echoes and good Weapons. This mode offers a reasonable mix of Boss
              encounters (single target), Elite packs (2-3 targets) and AoE
              scenarios (5+ Targets), allowing multiple characters to find a
              niche but in the end, there's a bias toward single target focused
              characters at the top-end.
            </p>
            <p>
              Please keep in mind Wuthering Waves is a skill-based game and each
              character has a different playstyle, difficulty level and even
              special tricks and combos. Wuthering Waves is also a team based
              game and it's only by combining both these factors to the highest
              level a character can reach optimal performance.
            </p>
          </div>
          {character.ratings.tower != 1 ? (
            <>
              <div className="ratings-container">
                <RatingBox
                  game="hsr"
                  rating={character.ratings.tower}
                  ratingName="Tower of Adversity"
                />
              </div>
            </>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                ratings aren't available yet. They will be added as soon as it
                is possible.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Video guides
          </div>
          {character.videos && character.slug != 'aalto' ? (
            <Row xs={1} xl={3} className="video-row">
              {character.videos.map((videoLink, idx) => {
                return (
                  <Col key={idx}>
                    <YoutubeEmbed embedId={videoLink.video} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>guide videos aren't available yet.</strong>
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build
            </p>
          </div>
          {character.buildInfoEcho ? (
            <>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best Weapons
              </div>
              {character.slug === 'jianxin' && (
                <>
                  <h6>Support configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Support role, here are the Weapons you should
                    consider using on her:
                  </p>
                  <div className="build-tips with-margin">
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWWeaponEntry
                        name="Originite: Type IV"
                        level={[5]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <WWWeaponNotes name="Originite: Type IV" />
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage`}>
                        <p>2</p>
                      </div>
                      <WWWeaponEntry
                        name="Marcato"
                        level={[1]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <WWWeaponNotes name="Marcato" />
                  </div>
                  <h6>DPS/Hybrid configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the DPS/Hybrid roles, here's how much her damage output
                    will be affected by the Weapons:
                  </p>
                </>
              )}
              {character.slug === 'yangyang' && (
                <>
                  <h6>Support configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Support role, here are the Weapons you should
                    consider using on her:
                  </p>
                  <div className="build-tips with-margin">
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWWeaponEntry
                        name="Originite: Type II"
                        level={[5]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <WWWeaponNotes name="Originite: Type II" />
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage`}>
                        <p>2</p>
                      </div>
                      <WWWeaponEntry
                        name="Overture"
                        level={[1]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <WWWeaponNotes name="Overture" />
                    <div className={`single-item ${character.element}`}>
                      <div className={`percentage`}>
                        <p>3</p>
                      </div>
                      <WWWeaponEntry
                        name="Sword of Voyager"
                        level={[5]}
                        mode="icon"
                        onProfile
                      />
                    </div>
                    <WWWeaponNotes name="Sword of Voyager" />
                  </div>
                  <h6>Hybrid configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Hybrid role, here's how much her damage output will
                    be affected by the Weapons:
                  </p>
                </>
              )}
              <div className="build-tips">
                {character.buildInfoWeapon && (
                  <>
                    {character.buildInfoWeapon.map((weapon, idx) => {
                      return (
                        <>
                          {weapon.weapon != '' && (
                            <>
                              <div
                                className={`single-item ${character.element}`}
                                key={idx}
                              >
                                <div className={`percentage`}>
                                  <p>
                                    {weapon.percent ? (
                                      <>{weapon.percent}</>
                                    ) : (
                                      <>{idx + 1}</>
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <WWWeaponEntry
                                    name={weapon.weapon}
                                    level={[Number(weapon.super)]}
                                    mode="icon"
                                    onProfile
                                  />
                                </div>
                              </div>
                              <WWWeaponNotes
                                name={weapon.weapon}
                                supere={weapon.super}
                                char={character.slug}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best Echo Sets
              </div>
              {character.slug === 'zhezhi' && (
                <p>
                  Moonlit Clouds set is the{' '}
                  <strong>primary set you should be using</strong> and while it
                  leads to lower personal damage, it helps the team more. Still,
                  if you want to play Zhezhi more in the DPS way, you can use
                  Freezing Frost, but it will lead to lower team damage.
                </p>
              )}
              {character.slug === 'jianxin' && (
                <>
                  <h6>Support configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Support role, here's what you should use on her (also
                    make sure to use the{' '}
                    <strong>Originite: Type IV weapon</strong> to allow her to
                    easily trigger Healing and the set effect):
                  </p>
                  <div className="build-tips with-margin">
                    <div
                      className={`single-item without-notes ${character.element}`}
                    >
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWSet name="Rejuvenating Glow" mode="profile" />
                    </div>
                  </div>
                  <h6>Hybrid configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Hybrid role, here's what you should use on her:
                  </p>
                  <div className="build-tips with-margin">
                    <div
                      className={`single-item without-notes ${character.element}`}
                    >
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWSet name="Moonlit Clouds" mode="profile" />
                    </div>
                  </div>
                  <h6>DPS configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the DPS role, here's what you should use on her:
                  </p>
                </>
              )}
              {character.slug === 'yuanwu' && (
                <>
                  <h6>Support configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Support role, here's what you should use on him (also
                    make sure to use the{' '}
                    <strong>Originite: Type IV weapon</strong> to allow him to
                    easily trigger Healing and the set effect):
                  </p>
                  <div className="build-tips with-margin">
                    <div
                      className={`single-item without-notes ${character.element}`}
                    >
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWSet name="Rejuvenating Glow" mode="profile" />
                    </div>
                  </div>
                  <h6>Hybrid configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Hybrid role, here's what you should use on him:
                  </p>
                </>
              )}
              {character.slug === 'yangyang' && (
                <>
                  <h6>Support configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Support role, here's what you should use on her (also
                    make sure to use the{' '}
                    <strong>Originite: Type II weapon</strong> to allow her to
                    easily trigger Healing and the set effect):
                  </p>
                  <div className="build-tips with-margin">
                    <div
                      className={`single-item without-notes ${character.element}`}
                    >
                      <div className={`percentage`}>
                        <p>1</p>
                      </div>
                      <WWSet name="Rejuvenating Glow" mode="profile" />
                    </div>
                  </div>
                  <h6>Hybrid configuration</h6>
                  <p>
                    If you're playing{' '}
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    in the Hybrid role, here's what you should use on her:
                  </p>
                </>
              )}
              <div className="build-tips">
                {character.buildInfoEcho && (
                  <>
                    {character.buildInfoEcho.echo_sets.map((set, idx) => {
                      return (
                        <>
                          <div
                            className={`single-item ${character.element}`}
                            key={idx}
                          >
                            <div className={`percentage`}>
                              <p>
                                {set.value ? <>{set.value}</> : <>{idx + 1}</>}
                              </p>
                            </div>
                            <div>
                              {set.name2 != '' ? (
                                <div className="double-set">
                                  <WWSet name={set.name} mode="profile" />
                                  <WWSet name={set.name2} mode="profile" />
                                </div>
                              ) : (
                                <>
                                  <WWSet name={set.name} mode="profile" />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="information">
                            {character.slug === 'yangyang' && (
                              <>
                                {set.notes && (
                                  <p className="notes">{set.notes}</p>
                                )}
                              </>
                            )}
                            {set.name === 'Endless Resonance' &&
                              set.name2 === '' && (
                                <>
                                  {set.notes && (
                                    <p className="notes">{set.notes}</p>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best Main Echoes
              </div>
              <Accordion className="default-look about-echoes">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <strong className="red">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        width="18"
                      />
                    </strong>
                    &nbsp;About Main Echoes skills
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Echoes in Wuthering Waves are an essential part of the
                      character rotations, granting a large amount of Resonance
                      Energy and damage but at the cost of high field time for
                      the most powerful ones. But by using some Echoes right
                      before you're about to switch out on the character, you're
                      able to move to your next character while your previous
                      one is still in the echo animation -{' '}
                      <strong>it’s what we call an Echo Swap Cancel</strong>.
                    </p>
                    <p>
                      Echo Swap Cancel is almost always the optimal way to use
                      Echoes, and will result in big reductions in character
                      field time and large increases to DPS, but not all of the
                      most powerful Echoes can be swap cancelled in this way.
                      For example, the <strong>Feilian Beringal</strong> (
                      <strong className="Aero">Aero</strong>),{' '}
                      <strong>Dreamless</strong> (
                      <strong className="Havoc">Havoc</strong>),{' '}
                      <strong>Tempest Mephis</strong> (
                      <strong className="Electro">Electro</strong>),{' '}
                      <strong>Mourning Aix</strong> (
                      <strong className="Spectro">Spectro</strong>), and{' '}
                      <strong>Infernal Rider</strong> (
                      <strong className="Fusion">Fusion</strong>) Echoes can all
                      be Swap Cancelled.
                    </p>
                    <p>
                      The remaining element, so{' '}
                      <strong className="Glacio">Glacio</strong> while having
                      powerful Echoes{' '}
                      <strong>
                        has none powerful enough that can be swap cancelled
                      </strong>
                      , and to make things worse, no Echoes that generate
                      sufficient Energy and damage for their field time
                      investment.
                    </p>
                    <p>
                      The result of this is that you can expect{' '}
                      <strong className="Glacio">Glacio</strong> characters to{' '}
                      <strong>
                        have higher field time and lower DPS in calculations
                      </strong>{' '}
                      than their other elemental counter parts until they
                      receive better Echoes to use.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <WWMainEchoesProfile
                name={character.slug}
                role={character.tierCategory}
              />
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best Echo Stats
              </div>
              <h6>4-3-3-1-1:</h6>
              <div className="main-stats">
                <div className="box">
                  <div className="stats-inside">
                    <strong className="Overlord">4 cost</strong>
                  </div>
                  <div className="list-stats">
                    {character.buildInfoEcho.echo_4_stat && (
                      <>
                        {character.buildInfoEcho.echo_4_stat.map(
                          (stat, idx) => {
                            return <WWStat key={idx} stat={stat.stat} />;
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="stats-inside">
                    <strong className="Elite">3 cost</strong>
                  </div>
                  <div className="list-stats">
                    {character.buildInfoEcho.echo_3_stat && (
                      <>
                        {character.buildInfoEcho.echo_3_stat.map(
                          (stat, idx) => {
                            return <WWStat key={idx} stat={stat.stat} />;
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="stats-inside">
                    <strong className="Elite">3 cost</strong>
                  </div>
                  <div className="list-stats">
                    {character.buildInfoEcho.echo_3a_stat && (
                      <>
                        {character.buildInfoEcho.echo_3a_stat.map(
                          (stat, idx) => {
                            return <WWStat key={idx} stat={stat.stat} />;
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="stats-inside">
                    <strong className="Common">1 cost</strong>
                  </div>
                  <div className="list-stats">
                    {character.buildInfoEcho.echo_1_stat && (
                      <>
                        {character.buildInfoEcho.echo_1_stat.map(
                          (stat, idx) => {
                            return <WWStat key={idx} stat={stat.stat} />;
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="box">
                  <div className="stats-inside">
                    <strong className="Common">1 cost</strong>
                  </div>
                  <div className="list-stats">
                    {character.buildInfoEcho.echo_1a_stat && (
                      <>
                        {character.buildInfoEcho.echo_1a_stat.map(
                          (stat, idx) => {
                            return <WWStat key={idx} stat={stat.stat} />;
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <WWEchoAlt name={character.slug} />
              <Row>
                <Col>
                  <div className="box sub-stats">
                    <p>
                      <span>Substats:</span>{' '}
                      {character.buildInfoOther.substats
                        ? character.buildInfoOther.substats
                        : '-'}
                    </p>
                  </div>
                </Col>
              </Row>
              <WWEchoStatsComparison name={character.slug} />
              {character.statsInfo && (
                <>
                  <p className="with-margin">
                    If you want to learn more about the various stats and
                    breakpoints, expand the section below.
                  </p>
                  <Accordion className="default-look">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Details about the Stats
                      </Accordion.Header>
                      <Accordion.Body>
                        <>{renderRichText(character.statsInfo, options)}</>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best Endgame
                Stats (Level 90)
              </div>
              <p>
                <strong>Important!</strong> CRIT DMG has base value of 150% and
                Energy Regen has a base value of 100% when you check them in
                game.{' '}
                <strong className="red">
                  The recommended values below don't include the base value
                </strong>{' '}
                but rather show how much on top of it you need to obtain.
              </p>
              {character.endgameStats ? (
                <div className="section-analysis">
                  <div className={`box review raw`}>
                    {character.endgameStats && (
                      <>{renderRichText(character.endgameStats, options)}</>
                    )}
                  </div>
                </div>
              ) : (
                <div className="info-box">
                  <p>
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    <strong>endgame stats aren't available yet.</strong> They
                    will be added soon.
                  </p>
                </div>
              )}
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Skill Priority
              </div>
              <p>
                Below you can find the order of leveling the abilities for{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>
                .
              </p>
              <WWSkillPrioProfile
                name={character.slug}
                element={character.element}
              />
            </>
          ) : (
            <>
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Best build
              </div>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>build information aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Teams' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
              Gameplay and teams
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Rotation
          </div>
          <WWCharacterCalcs
            slug={character.slug}
            name={character.name}
            element={character.element}
            onlyRotation
          />
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Synergies
          </div>
          {character.upcoming != true ? (
            <>
              <WWTeams
                name={character.slug}
                element={character.element}
                role={character.tierCategory}
              />
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>gameplay notes aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Calculations
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Damage Profile
          </div>
          {character.slug != 'taoqi' &&
          character.slug != 'verina' &&
          character.slug != 'yangyang' &&
          character.slug != 'baizhi' &&
          character.upcoming != true ? (
            <>
              <WWDmgDistribution
                name={character.name}
                slug={character.slug}
                element={character.element}
              />
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>damage profile isn't available yet.</strong> It will
                  be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Damage Output
          </div>
          <WWCharacterCalcs
            slug={character.slug}
            name={character.name}
            element={character.element}
          />
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default WWCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' Guide and Build | Wuthering Waves | Prydwen'}
      description={
        character.name +
        ' is a character in Wuthering Waves. Find their best builds, teams, rotations, and other information here.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulWwCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...WWCharacterFieldsFragment
      }
    }
  }
`;
