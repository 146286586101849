/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Chart from 'react-google-charts';

interface IProps {
  name: string;
  slug: string;
  element: string;
}

export const dataRover = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 19801],
  ['Skill', 92700],
  ['Liberation', 70777],
  ['Intro', 10340],
  ['Outro', 0],
  ['Echo', 21123]
];

export const dataRoverHavoc = [
  ['Skill', 'Dmg'],
  ['Basic', 105493],
  ['Heavy', 14249],
  ['Skill', 77694],
  ['Liberation', 97326],
  ['Intro', 10698],
  ['Outro', 26593],
  ['Echo', 40663]
];

export const dataJiyan = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 272658],
  ['Skill', 58276],
  ['Liberation', 0],
  ['Intro', 12496],
  ['Outro', 39398],
  ['Echo', 25037]
];

export const dataDanjin = [
  ['Skill', 'Dmg'],
  ['Basic', 15868],
  ['Heavy', 92288],
  ['Skill', 72601],
  ['Liberation', 45028],
  ['Intro', 10453],
  ['Outro', 0],
  ['Echo', 29848]
];

export const dataJianxin = [
  ['Skill', 'Dmg'],
  ['Basic', 50846],
  ['Heavy', 83232],
  ['Skill', 12995],
  ['Liberation', 59638],
  ['Intro', 8123],
  ['Outro', 0],
  ['Echo', 24763]
];

export const dataCalcharo = [
  ['Skill', 'Dmg'],
  ['Basic', 76592],
  ['Heavy', 0],
  ['Skill', 23775],
  ['Liberation', 214872],
  ['Intro', 21049],
  ['Outro', 31124],
  ['Echo', 21700]
];

export const dataEncore = [
  ['Skill', 'Dmg'],
  ['Basic', 166881],
  ['Heavy', 0],
  ['Skill', 48607],
  ['Liberation', 49496],
  ['Intro', 11763],
  ['Outro', 43620],
  ['Echo', 47806]
];

export const dataYinlin = [
  ['Skill', 'Dmg'],
  ['Basic', 15577],
  ['Heavy', 18905],
  ['Skill', 113951],
  ['Liberation', 43103],
  ['Intro', 7239],
  ['Outro', 0],
  ['Echo', 20723]
];

export const dataYangyang = [
  ['Skill', 'Dmg'],
  ['Basic', 35121],
  ['Heavy', 0],
  ['Skill', 15988],
  ['Liberation', 42073],
  ['Intro', 4330],
  ['Outro', 0],
  ['Echo', 13406]
];

export const dataMortefi = [
  ['Skill', 'Dmg'],
  ['Basic', 18653],
  ['Heavy', 0],
  ['Skill', 39578],
  ['Liberation', 59677],
  ['Intro', 6684],
  ['Outro', 0],
  ['Echo', 7141]
];

export const dataAalto = [
  ['Skill', 'Dmg'],
  ['Basic', 18976],
  ['Heavy', 0],
  ['Skill', 28260],
  ['Liberation', 15698],
  ['Intro', 7459],
  ['Outro', 0],
  ['Echo', 6774]
];

export const dataLingyang = [
  ['Skill', 'Dmg'],
  ['Basic', 153324],
  ['Heavy', 11647],
  ['Skill', 92803],
  ['Liberation', 26414],
  ['Intro', 12693],
  ['Outro', 82154],
  ['Echo', 31196]
];

export const dataSanhua = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 32548],
  ['Skill', 27876],
  ['Liberation', 34495],
  ['Intro', 5636],
  ['Outro', 0],
  ['Echo', 7312]
];

export const dataChixia = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 130897],
  ['Liberation', 93774],
  ['Intro', 9822],
  ['Outro', 30041],
  ['Echo', 38156]
];

export const dataYuanwu = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 32594],
  ['Liberation', 32473],
  ['Intro', 5194],
  ['Outro', 0],
  ['Echo', 8317]
];

export const dataJinhsi = [
  ['Skill', 'Dmg'],
  ['Basic', 0],
  ['Heavy', 0],
  ['Skill', 450699],
  ['Liberation', 104077],
  ['Intro', 26231],
  ['Outro', 0],
  ['Echo', 13371]
];

export const dataChangli = [
  ['Skill', 'Dmg'],
  ['Basic', 18569],
  ['Heavy', 7799],
  ['Skill', 233644],
  ['Liberation', 95324],
  ['Intro', 9003],
  ['Outro', 0],
  ['Echo', 49040]
];

export const dataZhezhi = [
  ['Skill', 'Dmg'],
  ['Basic', 144014],
  ['Heavy', 11376],
  ['Skill', 13488],
  ['Liberation', 0],
  ['Intro', 11179],
  ['Outro', 0],
  ['Echo', 8395]
];

export const dataXiangli = [
  ['Skill', 'Dmg'],
  ['Basic', 39959],
  ['Heavy', 0],
  ['Skill', 73108],
  ['Liberation', 229629],
  ['Intro', 11050],
  ['Outro', 42521],
  ['Echo', 26197]
];

export const options = {
  is3D: true,
  pieSliceText: 'percentage',
  backgroundColor: '#2C2D33',
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: { color: '#ffffff' },
    scrollArrows: { inactiveColor: '#ffffff', activeColor: '#ffffff' },
    pagingTextStyle: { color: '#ffffff' }
  },
  chartArea: { top: 20 },
  slices: {
    0: { color: '#009EEC' },
    1: { color: '#C267EC' },
    2: { color: '#f75058' },
    3: { color: '#56b972' },
    4: { color: '#76F8F8' },
    5: { color: '#F9A2A2' },
    6: { color: '#e29c55' }
  }
};

export const WWDmgDistribution: React.FC<IProps> = ({
  name,
  slug,
  element
}) => {
  return (
    <>
      <div className="damage-distribution">
        <div className="top-part">
          <h5>
            <strong className={element}>{name}</strong> damage profile
          </h5>
        </div>
        {slug === 'xiangli-yao' && (
          <Chart
            chartType="PieChart"
            data={dataXiangli}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'zhezhi' && (
          <Chart
            chartType="PieChart"
            data={dataZhezhi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'changli' && (
          <Chart
            chartType="PieChart"
            data={dataChangli}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jinhsi' && (
          <Chart
            chartType="PieChart"
            data={dataJinhsi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'rover-spectro' && (
          <Chart
            chartType="PieChart"
            data={dataRover}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'yuanwu' && (
          <Chart
            chartType="PieChart"
            data={dataYuanwu}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'rover-havoc' && (
          <Chart
            chartType="PieChart"
            data={dataRoverHavoc}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jiyan' && (
          <Chart
            chartType="PieChart"
            data={dataJiyan}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'danjin' && (
          <Chart
            chartType="PieChart"
            data={dataDanjin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'jianxin' && (
          <Chart
            chartType="PieChart"
            data={dataJianxin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'calcharo' && (
          <Chart
            chartType="PieChart"
            data={dataCalcharo}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'encore' && (
          <Chart
            chartType="PieChart"
            data={dataEncore}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'yinlin' && (
          <Chart
            chartType="PieChart"
            data={dataYinlin}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {/* {slug === 'yangyang' && (
          <Chart
            chartType="PieChart"
            data={dataYangyang}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )} */}
        {slug === 'mortefi' && (
          <Chart
            chartType="PieChart"
            data={dataMortefi}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'aalto' && (
          <Chart
            chartType="PieChart"
            data={dataAalto}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'lingyang' && (
          <Chart
            chartType="PieChart"
            data={dataLingyang}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'sanhua' && (
          <Chart
            chartType="PieChart"
            data={dataSanhua}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
        {slug === 'chixia' && (
          <Chart
            chartType="PieChart"
            data={dataChixia}
            options={options}
            width={'100%'}
            height={'400px'}
          />
        )}
      </div>
    </>
  );
};
