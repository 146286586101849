/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WWCharacter } from '../common/components/ww-character';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../common/components/youtube-embed';

interface IProps {
  name: string;
  element: string;
  role: string;
}

export const WWTeams: React.FC<IProps> = ({ name, element, role }) => {
  return (
    <>
      {name != 'verina' &&
        name != 'baizhi' &&
        name != 'yangyang' &&
        name != 'taoqi' && (
          <div className="character-synergy box">
            {name === 'xiangli-yao' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Yinlin is one of the most synergistic characters with
                      Xiangli Yao at the time of his release, primarily thanks
                      to her tremendously powerful Outro ability which grants
                      both 20% Electro DMG amplification and 25% Liberation DMG
                      amplification for 14 seconds. This buff is a tremendous
                      boost to Xiangli Yao's ultimate burst window with all of
                      his damage being Electro and over 50% of it being
                      Liberation, the duration is also just right to fully buff
                      the entirety of it assuming you don't make any mistakes.
                      Yinlin also does respectable damage of her own and can run
                      the Moonlit Clouds set if desired to further boost Xiangli
                      Yao's burst damage potential.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="changli"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      As a quick swap specialist Changli very much prefers to be
                      constantly switched in and out and can dish out some
                      serious damage when played in this style correctly.
                      Xiangli Yao has both the option to stay on field during
                      his ultimate to take advantage of powerful amplification
                      Outros but also to swap cancel any of his incredibly long
                      attack animations for massive damage gains. Changli
                      specifically excels at option 2 and is easily able to
                      weave her attacks and combos throughout Xiangli Yao's
                      ultimate burst window without you having to worry thanks
                      to his tremendously long ultimate burst window. Changli
                      also has a Liberation DMG% bonus on her Outro but it's
                      generally far more productive to focus on her quick swap
                      potential instead. Combining Changli and Xiangi Yao forms
                      a fast paced double damage power house team which requires
                      a bit of practice but is a satisfying duo.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Sanhua doesn't offer anything crazy in the personal damage
                      department or any tremendously powerful Outro
                      amplifications for Xiangli Yao but she is one of the best
                      and most efficient carriers of the Moonlit Clouds set,
                      something which absolutely will make a difference to your
                      Xiangli Yao's damage. On top of the Moonlit set Sanhua
                      also takes up very little field time and generates a
                      substantial amount of resonance energy for the team,
                      something which might make the difference between Xiangli
                      Yao gaining his ultimate just a bit faster or saving you
                      needing to chase down any energy rolls on gear. Finally of
                      course Sanhua gains a considerable increase to her
                      supportive capabilities with Sequences so if you have
                      those her supportive capabilities only further increase
                      (specifically her S6).
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'zhezhi' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="lingyang"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Lingyang is our only Glacio Main damage dealer currently,
                      making him an obvious choice to take advantage of Zhezhi's
                      outro Glacio DMG Amplification. Lingyang's longer
                      rotations without quick swaps also have no issues working
                      well with Zhezhi's infrequent but quick rotations she
                      executes every 25 seconds. Both Zhezhi and Lingyang
                      synergise decently but more importantly each allow
                      eachother to take up exactly how much field time they
                      want.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="jinhsi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Jinhsi thrives in teams with teammates that can launch
                      consistent follow-up attacks from off-field, preferably
                      without taking up to much field time and Zhehzi fits that
                      bill perfectly. If that wasn't enough though Zhezhi also
                      offers Resonance Skill DMG Amplification on her outro and
                      Jinhsi is currently the biggest Resonance Skill damage
                      dealer in the game with roughly 75% of her damage being of
                      the type. Finally Zhezhi also provides Jinhsi a bit of
                      extra resonance energy allowing for more ultimates which
                      is always welcome.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'changli' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Changli thrives with a partner who can take advantage of
                      the Quick Swap opportunities she presents - Yinlin as a
                      Quick Swap specialist herself has incredible synergy with
                      her for that reason. Cycling between Yinlin's Forte,
                      Resonance Skill, Liberation, Echo and all of Changli's
                      cooldown-based combos makes for an incredibly synergistic
                      partnership - all topped off by the fact Yinlin can
                      deliver a good amount of her damage off field, allowing
                      Changli to take up the extra field time her Kit demands.
                      On top of this, both Changli and Yinlin each buff each
                      other Liberation damage with their Outros, enhancing each
                      of their damage contributions by a decent amount. Yinlin
                      and Changli aren't a perfect match but they work well
                      together for those happy with a rapid Quick Swap
                      playstyle.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="encore"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Encore</strong> has the option to gear her
                      playstyle towards being a top tier Quick Swap damage
                      dealer, making her a fantastic partner for Changli. Encore
                      can weave her Resonance Skill, Echo and Forte/Ultimate
                      burst in between Changli's cooldowns to fill the brief
                      moments granted by her Quick Swap nature, ensuring the
                      team's DPS stays high. On top of this Encore can make use
                      of the Fusion Damage amplification portion of Changli's
                      Outro skill boosting the damage of some of her abilities
                      throughout combat.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="rover-havoc"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Rover Havoc</strong> is one of the top Quick Swap
                      damage dealers in the game, making them a great potential
                      team mate for Changli who also falls within the same
                      archetype. During the Quick Swap opportunities granted by
                      Changli, Rover can make use of either or both their
                      Resonance Skills, their Ultimate and their incredibly
                      powerful dreamless echo. All of these abilities are great
                      complimentary cooldowns to Changli's kit and combat
                      rotation dealing high damage and being fast enough to
                      allow for an immediate swap back to Changli as needed.
                      Rover's Ultimate also hits like a freight train -
                      something Changli can amplify further thanks to her Outro
                      if aligned correctly further boosting synergy.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Verina</strong> as a healing support can easily
                      accommodate running the 5P Rejuvenating Glow set alongside
                      a Bell-Borne Geochelone echo, offering the team a wide
                      array of offensive and defensive buffs already bringing a
                      tonne of worth just from that alone.
                    </p>
                    <p>
                      Additionally Verina's many rotational options to access
                      her Ultimate and Concerto are all fast and relatively easy
                      to execute, leaving ample field time for the rest of team
                      mates to execute their combos. Verina's Outro is by far
                      the most flexible and powerful Outro in the game granting
                      all allies 15% generic DMG% "Deepen" for 30 seconds. Most
                      units only grant 1 type and only for the very next unit to
                      switch in locking them into staying on field less they
                      lose the buff but Verina grants it to all allies
                      completely unrestricted.
                    </p>
                    <p>
                      On top of this Verina grants an additional 20% ATK and a
                      Cheat Death ability preventing one death every 10 minutes.
                      She's universal, flexible, takes little field time and
                      outstanding in every team.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'yuanwu' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="jinhsi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Yuanwu is an excellent Hybrid partner for{' '}
                      <strong>Jinhsi</strong> who - out of all of her potential
                      hybrid teammates - requires the least field time by far.
                      Yuanwu doesn't offer to much in terms of total damage or
                      buffs but he does offer a consistent permanently
                      accessible Electro Coordinated Attack to assist with
                      stacking up Jinhsi's Forte Gauge. Yuanwu is absolutely a
                      top option for Jinhsi and should not be underestimated -
                      he is easy to play, gives her what she needs and allows
                      for you to focus on playing 2 characters for the most part
                      instead of 3 due to his simplistic nature.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'jinhsi' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="yuanwu"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Yuanwu</strong> is an excellent Hybrid partner for
                      Jinhsi who - out of all of her potential hybrid teammates
                      - requires the least field time by far. Yuanwu doesn't
                      offer to much in terms of total damage or buffs but he
                      does offer a consistent permanently accessible Electro
                      Coordinated Attack to assist with stacking up Jinhsi's
                      Forte Gauge. Yuanwu is absolutely a top option for Jinhsi
                      and should not be underestimated - he is easy to play,
                      gives her what she needs and allows for you to focus on
                      playing 2 characters for the most part instead of 3 due to
                      his simplistic nature.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="zhezhi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Jinhsi thrives in teams with teammates that can launch
                      consistent follow-up attacks from off-field, preferably
                      without taking up to much field time and Zhehzi fits that
                      bill perfectly. If that wasn't enough though Zhezhi also
                      offers Resonance Skill DMG Amplification on her outro and
                      Jinhsi is currently the biggest Resonance Skill damage
                      dealer in the game with roughly 75% of her damage being of
                      the type. Finally Zhezhi also provides Jinhsi a bit of
                      extra resonance energy allowing for more ultimates which
                      is always welcome.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Yinlin</strong> is currently one of the best
                      teammates for Jinhsi... if you can manage playing them
                      both effectively at once! Out of all Jinhsi's current
                      Hybrid teammates, Yinlin is the hardest to play optimally
                      due to her having the strictest requirements to access her
                      Coordinated Attacks. If you're a master of quick swapping,
                      swap cancelling and are ok with a bit more of a
                      complicated team to play, Yinlin will certainly reward you
                      though. Like Yuanwu she can permanently keep active a
                      source of Electro Coordinated Attacks for Jinhsi's Forte
                      Gauge generation, but on top of this Yinlin also does
                      outstanding damage of her own allowing her to pull ahead
                      when played just right.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Verina</strong> is Jinhsi's best dedicated
                      support. Verina's ultimate applies a mark to enemies
                      struck which launches continual Coordinated Attacks across
                      its duration - these coordinated attacks assist Jinhsi
                      with generating her Forte Gauge Incandecent stacks
                      boosting her damage tremendously. Another massive benefit
                      to Jinhsi + Verina is how fast and easy Verina's concerto
                      rotation is - this allows Jinhsi to gain access to her
                      intro and as a result her burst rotation much faster and
                      more effectively smoothing out gameplay immensely. Jinhsi
                      can be hard to control in the air during her burst combo
                      which can lead to her taking some hits here and there and
                      Verina is more than capable of keeping Jinhsi healthy at
                      all times. Finally Verina has her massive suite of buffs
                      she offers Jinhsi all of which increase her damage
                      massively.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'jiyan' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Mortefi</strong> excels at buffing Heavy ATK DMG
                      with his "Deepen" Outro which acts as an immense
                      multiplier thanks to the special "Deepen" scaling
                      mechanic. Jiyan demands high field time and Mortefi takes
                      up very little, making the pair an ideal combo of burst
                      and buffs with plenty of wiggle room to fit in a support
                      or some extra action on Jiyan while waiting for cooldowns
                      or energy to recharge.
                    </p>
                    <p>
                      Mortefi's Ultimate unleashes a slew of Coordinated Attacks
                      over its duration but requires a high hit counter to
                      maximize the effect. During his ultimate, Jiyan attacks 8
                      times per dragon strike easily maxing out this
                      requirement. Mortefi's ultimate also fires 2 Coordinated
                      Attacks instead of 1 when the triggering hit is a Heavy
                      Attack - all of which Jiyan's empowered dragon strikes are
                      in his ultimate.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="aalto"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Aalto</strong> isn't Jiyan's best support but he
                      certainly gets the job done coming close enough to the
                      total team damage output of Mortefi teams when all units
                      are at S0 albeit with a few downsides.
                    </p>
                    <p>
                      Aalto's main claim to fame is his 23% Aero DMG Deepen buff
                      that like Mortefi's is a special multiplier boosting up
                      Jiyan's entire burst window. This alone matches the Heavy
                      Deepen bonus from Mortefi as not all of Jiyan's attacks
                      are Heavy but all of them are Aero. While Aalto's Aero
                      buff is excellent, he doesn't have many other damage
                      focused synergies with Jiyan and has a slightly longer
                      rotation, requires some positional maneuvering around his
                      Gates and is harder to play overall. If you like Aalto and
                      or don't like or get Mortefi he is a good substitute.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'calcharo' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Yinlin</strong> is the best partner for Calcharo
                      as her playstyle is very similar to his - they both want
                      to have their skills swap cancelled as often as possible.
                      Just keep in mind that you won't really make use of the
                      Yinlin's Deepen buff too much due to the constant
                      switching and that the combination has the highest
                      skill-cap in the game to play properly, but if you do,
                      it's simply insane.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'yinlin' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="xiangli-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Yinlin is one of the most synergistic characters with
                      Xiangli Yao at the time of his release, primarily thanks
                      to her tremendously powerful Outro ability which grants
                      both 20% Electro DMG amplification and 25% Liberation DMG
                      amplification for 14 seconds. This buff is a tremendous
                      boost to Xiangli Yao's ultimate burst window with all of
                      his damage being Electro and over 50% of it being
                      Liberation, the duration is also just right to fully buff
                      the entirety of it assuming you don't make any mistakes.
                      Yinlin also does respectable damage of her own and can run
                      the Moonlit Clouds set if desired to further boost Xiangli
                      Yao's burst damage potential.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="calcharo"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Yinlin is the great partner for Calcharo as her playstyle
                      is very similar to his - they both want to have their
                      skills swap cancelled as often as possible. Just keep in
                      mind that you won't really make use of the Yinlin's Deepen
                      buff too much due to the constant switching and that the
                      combination has the highest skill-cap in the game to play
                      properly, but if you do, it's simply insane.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="rover-havoc"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      If playing Calcharo is just too much for you, you can use
                      Rover Havoc as the DPS partner for Yinlin. The Quick Swap
                      team with those two together is easier to play and perform
                      really well.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="encore"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Similar to Havoc Rover, you can use Encore as the DPS
                      partner for Yinlin. The Quick Swap team with those two
                      together is easier to play and perform really well.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'encore' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      A large bulk of Encore's damage during her ultimate are
                      Basic Attacks which <strong>Sanhua</strong> can buff with
                      her Outro skill - that increases Basic ATK DMG by 38%
                      "Deepen", increasing Encore damage substantially. Sanhua's
                      Outro is excellent but her field time is also some of the
                      shortest of the game allowing Encore and the support of
                      your choosing more time for themselves or simply a faster
                      rotation. At higher Sequences Sanhua's supportive
                      capabilities only improve further specially at Sequence 6
                      offering a 20% ATK team wide buff.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'lingyang' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="zhezhi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Lingyang is our only Glacio Main damage dealer currently,
                      making him an obvious choice to take advantage of Zhezhi's
                      outro Glacio DMG Amplification. Lingyang's longer
                      rotations without quick swaps also have no issues working
                      well with Zhezhi's infrequent but quick rotations she
                      executes every 25 seconds. Both Zhezhi and Lingyang
                      synergise decently but more importantly each allow
                      eachother to take up exactly how much field time they
                      want.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      A large bulk of Lingyang's damage during his ultimate are
                      Basic Attacks which <strong>Sanhua</strong> can buff with
                      her Outro skill - that increases Basic ATK DMG by 38%
                      "Deepen", increasing Lingyang damage substantially.
                      Sanhua's Outro is excellent but her field time is also
                      some of the shortest of the game allowing Lingyang and the
                      support of your choosing more time for themselves or
                      simply a faster rotation. At higher Sequences Sanhua's
                      supportive capabilities only improve further specially at
                      Sequence 6 offering a 20% ATK team wide buff.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'danjin' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="rover-havoc"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Havoc Rover benefits from Danjin's Deepen Outro and
                      together they form one of the strongest teams in the game
                      currently - equip both with Dreamless Echo and simply
                      destroy everything.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <strong>Mortefi</strong> excels at buffing Heavy ATK DMG
                      with his "Deepen" Outro which acts as an immense
                      multiplier thanks to the special "Deepen" scaling mechanic
                      of which Danjin deals quite a bit with her fully charged
                      Chaos Cleaves. Danjin desires high field time and Mortefi
                      takes up very little making the pair a strong combo of
                      high DPS and buffs with plenty of wiggle room to fit in a
                      support or even more field time for Danjin.
                    </p>
                    <p>
                      Mortefi's ultimate unleashes a slew of Coordinated Attacks
                      over its duration but requires a high hit counter to
                      maximize its effects, Danjin doesn't make perfect use of
                      this but does a pretty decent job of getting some value at
                      S0 - at S1 though Mortefi fires additional Marcatos per
                      Resonance Skill improving the synergy.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'sanhua' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="encore"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Encore requires a large amount of field time and deals a
                      good chunk of her damage with Basic ATK's making Sanhua a
                      great partner for her.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="lingyang"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Lingyang requires a large amount of field time and deals a
                      good chunk of his damage with Basic ATK's making Sanhua a
                      great partner for him.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="rover-havoc"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Rover Havoc requires a large amount of field time and
                      deals a good chunk of their damage with Basic ATK's making
                      Sanhua a great partner for them.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'jianxin' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="calcharo"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      A large portion of Calcharo's burst damage is Resonance
                      Liberation damage which <strong>Jianxin</strong> can buff
                      up with her 38% Resonance Liberation DMG % "Deepen" buff,
                      giving Calcharo a substantial boost to his main damage
                      window.
                    </p>
                    <p>
                      When playing Jianxin with Calcharo you'll need to focus on
                      maximizing her Concerto but keeping her field time low to
                      ensure Calcharo has all the time he needs for his combos.
                      Jianxin's Liberation is a great tool on top of her already
                      fantastic Outro boost for Calcharo, allowing her to group
                      up trash enemies for Calcharo to cleave down.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Mortefi excels at buffing heavy ATK DMG with his "Deepen"
                      Outro which acts as an immense multiplier thanks to the
                      special "Deepen" scaling mechanic of which Jianxian's
                      Primordial Chi Sprial is entirely Heavy.
                    </p>
                    <p>
                      When playing Jianxin as a damage dealer, she demands high
                      field time and Mortefi takes up very little making the
                      pair a decent combo of burst and buffs with enough room to
                      fit in a support. Mortefi's ultimate unleashes a slew of
                      Coordinated Attacks over its duration but requires a high
                      hit counter to maximize its effects. During her Forte
                      Gauge, Jianxin attacks over 3 times per second easily
                      maxing out this requirement. Mortefi's ultimate also fires
                      2 Coordinated Attack instead of 1 when the triggering hit
                      is a heavy attack all of which of course the entire
                      channel of Jianxin's Forte is.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'aalto' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="jiyan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Aalto isn't Jiyan's best support but he certainly gets the
                      job done coming close enough to the total team damage
                      output of Mortefi teams when all units are at S0 albeit
                      with a few downsides.
                    </p>
                    <p>
                      Aalto's main claim to fame is his 23% Aero DMG Deepen buff
                      that like Mortefi's is a special multiplier boosting up
                      Jiyan's entire burst window. This alone matches the Heavy
                      Deepen bonus from Mortefi as not all of Jiyan's attacks
                      are Heavy but all of them are Aero. While Aalto's Aero
                      buff is excellent, he doesn't have many other damage
                      focused synergies with Jiyan and has a slightly longer
                      rotation, requires some positional maneuvering around his
                      Gates and is harder to play overall. If you like Aalto and
                      or don't like or get Mortefi he is a good substitute.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'mortefi' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="jiyan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Mortefi excels at buffing Heavy ATK DMG with his "Deepen"
                      Outro which acts as an immense multiplier thanks to the
                      special "Deepen" scaling mechanic. Jiyan demands high
                      field time and Mortefi takes up very little, making the
                      pair an ideal combo of burst and buffs with plenty of
                      wiggle room to fit in a support or some extra action on
                      Jiyan while waiting for cooldowns or energy to recharge.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="jinhsi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Mortefi is another outstanding Coordinated Attack user who
                      is capable of supporting Jinhsi but relies heavily on
                      Sequences to get the job done to the standard of some of
                      his competitors in her teams. Jinhsi of course gains a
                      tremendous amount of damage from having allies constantly
                      launching coordinated attacks - something Mortefi can do
                      but at S0 only for 10 seconds at a time per ultimate.
                      Unfortunately Mortefi's ultimate has a cooldown of 20
                      seconds meaning you'll only have access to coordinated
                      attacks half the time. Mortefi's Sequences completely
                      solve this issue - at S4 he gains 7 additional seconds of
                      duration for his Coordinated Attacks bringing him up to 17
                      seconds of duration or close to 100% uptime and at S6 he
                      gains a 20% ATK boost for the whole team. Mortefi can't
                      bring his full kit to bear alongside Jinhsi (his 38% Heavy
                      amplification isn't used) but is still useful for Jinhsi
                      at S0 however he truly shines at S4 or higher.
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="danjin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      Mortefi excels at buffing Heavy ATK DMG with his "Deepen"
                      Outro which acts as an immense multiplier thanks to the
                      special "Deepen" scaling mechanic of which Danjin deals
                      quite a bit with her fully charged Chaos Cleaves. Danjin
                      desires high field time and Mortefi takes up very little
                      making the pair a strong combo of high DPS and buffs with
                      plenty of wiggle room to fit in a support or even more
                      field time for Danjin.
                    </p>
                  </div>
                </div>
              </>
            )}
            {name === 'rover-havoc' && (
              <>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="danjin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      <p>
                        Havoc Rover benefits from Danjin's Deepen Outro and
                        together they form one of the strongest teams in the
                        game currently - equip both with Dreamless Echo and
                        simply destroy everything.
                      </p>
                    </p>
                  </div>
                </div>
                <div className="single-character">
                  <div className="character-link">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character-info">
                    <p>
                      A large bulk of Rover (Havoc) damage are Basic Attacks
                      which <strong>Sanhua</strong> can buff with her Outro
                      skill - that increases Basic ATK DMG by 38% "Deepen",
                      increasing Rover (Havoc) damage substantially. Sanhua's
                      Outro is excellent but her field time is also some of the
                      shortest of the game allowing Rover (Havoc) and the
                      support of your choosing more time for themselves or
                      simply a faster rotation. At higher Sequences Sanhua's
                      supportive capabilities only improve further specially at
                      Sequence 6 offering a 20% ATK team wide buff.
                    </p>
                  </div>
                </div>
              </>
            )}
            {role != 'Support' && (
              <>
                {name != 'jinhsi' && name != 'changli' && (
                  <>
                    <div className="single-character">
                      <div className="character-link">
                        <WWCharacter
                          mode="icon"
                          slug="verina"
                          enablePopover
                          showLabel
                        />
                      </div>
                      <div className="character-info">
                        <p>
                          <strong>Verina</strong> as a healing support can
                          easily accommodate running the 5P Rejuvenating Glow
                          set alongside a Bell-Borne Geochelone echo, offering
                          the team a wide array of offensive and defensive buffs
                          already bringing a tonne of worth just from that
                          alone.
                        </p>
                        <p>
                          Additionally Verina's many rotational options to
                          access her Ultimate and Concerto are all fast and
                          relatively easy to execute, leaving ample field time
                          for the rest of team mates to execute their combos.
                          Verina's Outro is by far the most flexible and
                          powerful Outro in the game granting all allies 15%
                          generic DMG% "Deepen" for 30 seconds. Most units only
                          grant 1 type and only for the very next unit to switch
                          in locking them into staying on field less they lose
                          the buff but Verina grants it to all allies completely
                          unrestricted.
                        </p>
                        <p>
                          On top of this Verina grants an additional 20% ATK and
                          a Cheat Death ability preventing one death every 10
                          minutes. She's universal, flexible, takes little field
                          time and outstanding in every team.
                        </p>
                      </div>
                    </div>
                    <div className="single-character">
                      <div className="character-link">
                        <WWCharacter
                          mode="icon"
                          slug="baizhi"
                          enablePopover
                          showLabel
                        />
                      </div>
                      <div className="character-info">
                        <p>
                          If you don't have Verina, <strong>Baizhi</strong> is a
                          good alternative with very similar buffs and
                          capabilities albeit in a much more restricted fashion.
                          Her Outro instead of buffing all units only buffs the
                          next unit, her ATK% boost is only 20% and requires the
                          on field character interact with a special collectable
                          dropped by Baizhi on the ground, she takes up more
                          field time, generates less concerto and resonance
                          energy and heals less.
                        </p>
                        <p>
                          A downgrade across the board but still a potent option
                          for all the same reasons of Verina just on a smaller
                          scale. Verina can only be in one team if you have her
                          at all so Baihzi is a good next pick.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      {(name === 'verina' || name === 'baizhi' || name === 'yangyang') && (
        <>
          <p>
            <strong className={`char-name ${element} `}>{name}</strong>{' '}
            <strong>is very flexible</strong> and can be used in any team
            really.
          </p>
        </>
      )}
      {name === 'taoqi' && (
        <div className="info-box">
          <p>
            <strong className={`char-name ${element}`}>{name}</strong>{' '}
            <strong>synergies aren't available yet.</strong> They will be added
            soon.
          </p>
        </div>
      )}
      <div className={`content-header ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Example Teams
      </div>
      {name != 'chixia' ? (
        <>
          {name === 'zhezhi' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jinhsi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="zhezhi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="lingyang"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="zhezhi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {name === 'jinhsi' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jinhsi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="zhezhi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jinhsi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #3</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jinhsi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yuanwu"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <p>Here are the teams in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="R39BYwXU5Uc" />
                </Col>
              </Row>
            </>
          )}
          {name === 'xiangli-yao' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1 - Top Meta Team</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="xiangli-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-header">
                <p>Team #2 - Quick Swap Team</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="xiangli-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="changli"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-header">
                <p>Team #3 - Low Spender Team</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="xiangli-yao"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="baizhi"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'yuanwu' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jinhsi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="yuanwu"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'jiyan' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jiyan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'calcharo' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1 - Quick Swap</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="calcharo"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="yinlin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-notes">
                <ul>
                  <li>
                    Insane damage potential team but also insanely hard (like by
                    far the hardest team in the game) to play at the highest
                    level.
                  </li>
                  <li>
                    Calcharo can take advantage of Yinlin's Outro Electro and
                    Liberation amplification but due to the quick swap nature of
                    both character's it can lose impact unless specifically
                    played around perfectly.
                  </li>
                </ul>
              </div>
              <div className="team-header">
                <p>Team #2 - Hyper Carry</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="calcharo"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="baizhi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'changli' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="changli"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #2 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="changli"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #3 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="changli"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>Here's the Changli+Yinlin team in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="Z1uJBEKTzVE" />
                </Col>
              </Row>
            </>
          )}
          {name === 'encore' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jianxin"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2 - Hyper Carry</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="sanhua"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <p>Here's the Quick Swap team in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="ltF9WOV29v0" />
                </Col>
              </Row>
            </>
          )}
          {name === 'yinlin' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jinhsi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2 - With Xiangli Yao</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="xiangli-yao"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #3 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="calcharo"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <p>Support</p>
                    </div>
                  </div>
                </div>
                <div className="team-notes">
                  <ul>
                    <li>
                      Insane damage potential team but also insanely hard (like
                      by far the hardest team in the game) to play at the
                      highest level.
                    </li>
                    <li>
                      Calcharo can take advantage of Yinlin's Outro Electro and
                      Liberation amplification but due to the quick swap nature
                      of both character's it can lose impact unless specifically
                      played around perfectly.
                    </li>
                  </ul>
                </div>
                <div className="team-header">
                  <p>Team #4 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <p>Support</p>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #5 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="yinlin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <p>Support</p>
                    </div>
                  </div>
                </div>
                <div className="team-notes">
                  <ul>
                    <li>
                      Havoc Rover and Encore both offer great burst abilities
                      from their Resonance Skills and Echoes with Encore also
                      having the option to fill field time with her Resonance
                      Liberation and Havoc Rover having that same option but
                      from their Forte Umbra state. Brings the bursty Quick Swap
                      potential Yinlin works so well with but also with the
                      ability to fill downtime as needed while Yinlin and the
                      team waits for cooldowns.
                    </li>
                    <li>
                      Reasonably easy team combo to play - just use all of the
                      abilities on cooldown and ensure you execute Yinlin's
                      swaps and rotation correctly to assure Punishment Mark is
                      applied for as much as the battle as possible.
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <p>Here's the Encore/Rover Quick Swap team in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="YfqK055rz6U" />
                </Col>
              </Row>
            </>
          )}
          {name === 'sanhua' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1 - Hyper Carry</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="encore"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'lingyang' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="lingyang"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="zhezhi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-header">
                <p>Team #2</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="lingyang"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="sanhua"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'rover-havoc' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jianxin"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="danjin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #3 - Hyper Carry</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="sanhua"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <p>Here's the Quick Swap team in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="ltF9WOV29v0" />
                </Col>
              </Row>
            </>
          )}
          {name === 'danjin' && (
            <>
              <p>
                Unlike most characters Danjin is completely content fighting by
                herself thanks to her incredible sustained damage that stays
                stable even over incredibly long fights and very low reliance on
                her ultimate. Where most characters need to switch out to
                teammates to recharge their cooldowns or ultimate energy Danjin
                can keep going. Danjin can still benefit from a team but it is
                certainly not the only way or even most popular way to play her.
                But in case you want to play her in a team, here's an example
                one you can use:
              </p>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="danjin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="danjin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="mortefi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {name === 'jianxin' && (
            <>
              <div className={`character-teams box ${element}`}>
                <div className="team-header">
                  <p>Team #1 - Quick Swap</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="rover-havoc"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="encore"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jianxin"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Team #2 - Hyper Carry</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="jianxin"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="mortefi"
                        enablePopover
                        showLabel
                      />
                    </div>
                    <div className="character main">
                      <WWCharacter
                        mode="icon"
                        slug="verina"
                        enablePopover
                        showLabel
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <p>Here's the Quick Swap team in action:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="ltF9WOV29v0" />
                </Col>
              </Row>
            </>
          )}
          {name === 'aalto' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jiyan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="aalto"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'mortefi' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jiyan"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-header">
                <p>Team #2</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jinhsi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
              <div className="team-header">
                <p>Team #3</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="jianxin"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="mortefi"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {name === 'rover-spectro' && (
            <div className={`character-teams box ${element}`}>
              <div className="team-header">
                <p>Team #1</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="character main">
                    <p>Any Main DPS</p>
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="rover-spectro"
                      enablePopover
                      showLabel
                    />
                  </div>
                  <div className="character main">
                    <WWCharacter
                      mode="icon"
                      slug="verina"
                      enablePopover
                      showLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {(name === 'verina' ||
            name === 'baizhi' ||
            name === 'yangyang' ||
            name === 'taoqi') && (
            <>
              <p>
                <strong className={`char-name ${element}`}>{name}</strong>{' '}
                <strong>is very flexible</strong> and can be used in any team in
                the Support slot.{' '}
                {name === 'yangyang' && (
                  <>
                    Just make sure to equip the Originite: Type II Weapon and
                    Rejuvenating Glow set on Yangyang for her to perform well in
                    the Support slot.
                  </>
                )}
              </p>
            </>
          )}
        </>
      ) : (
        <div className="info-box">
          <p>
            <strong className={`char-name ${element}`}>{name}</strong>{' '}
            <strong>teams aren't available yet.</strong> They will be added
            soon.
          </p>
        </div>
      )}
    </>
  );
};
