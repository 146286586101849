export const WWCharactersDMG = [
  {
    id: 0,
    name: 'Rover (Spectro)',
    slug: 'rover-spectro',
    disclaimer:
      "To keep our character burst window evaluations consistent, we've used a full 100 Concerto energy rotation for Rover, but they are much more effectively played using quick swapping to take advantage of their incredibly short skill cooldown. When quick swapping expect to use less Basics, more Skills and Heavy Attacks and to deal more dps than listed.",
    dmg: {
      damage: 182432,
      damage_e1: 198382,
      damage_e2: 214740,
      damage_e3: 214740,
      damage_e4: 214740,
      damage_e5: 225423,
      damage_e6: 242839
    },
    dps: {
      damage: 27257,
      damage_e1: 29640,
      damage_e2: 32084,
      damage_e3: 32084,
      damage_e4: 32084,
      damage_e5: 33680,
      damage_e6: 36283
    },
    time: 6.69,
    total_dmg: 177713,
    echo_main: 'Mourning Aix',
    echo_4: 'CRIT DMG',
    echo_3: 'Spectro DMG',
    echo_3a: 'Spectro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Celestial Light',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 1,
    name: 'Jiyan',
    slug: 'jiyan',
    disclaimer: '',
    dmg: {
      damage: 407865,
      damage_e1: 407865,
      damage_e2: 446294,
      damage_e3: 557899,
      damage_e4: 598141,
      damage_e5: 705041,
      damage_e6: 911944
    },
    dps: {
      damage: 33503,
      damage_e1: 33503,
      damage_e2: 36660,
      damage_e3: 45827,
      damage_e4: 49133,
      damage_e5: 57914,
      damage_e6: 74909
    },
    time: 12.17,
    total_dmg: 407865,
    echo_main: 'Feilian Beringal',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Sierra Gale',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 2,
    name: 'Danjin',
    slug: 'danjin',
    disclaimer:
      'The calculations for Danjin do not include the Dodge Counter damage as it lies outside of the simulation rules. Still, if you will be able to use them consistently, her damage output will improve by a lot.',
    dmg: {
      damage: 266086,
      damage_e1: 294769,
      damage_e2: 318424,
      damage_e3: 324695,
      damage_e4: 337740,
      damage_e5: 374567,
      damage_e6: 400329
    },
    dps: {
      damage: 23843,
      damage_e1: 26413,
      damage_e2: 28533,
      damage_e3: 29095,
      damage_e4: 30263,
      damage_e5: 33563,
      damage_e6: 35872
    },
    time: 11.16,
    total_dmg: 259897,
    echo_main: 'Dreamless',
    echo_4: 'CRIT DMG',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Havoc Eclipse',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 3,
    name: 'Jianxin',
    slug: 'jianxin',
    disclaimer:
      'Our team is currently looking into alternative rotations for Jianxin to reduce her total field time while still accessing her Concerto, which could improve her utility and performance in challenging endgame.',
    dmg: {
      damage: 175179,
      damage_e1: 214173,
      damage_e2: 214173,
      damage_e3: 214173,
      damage_e4: 235007,
      damage_e5: 235007,
      damage_e6: 275007
    },
    dps: {
      damage: 16288,
      damage_e1: 18474,
      damage_e2: 18474,
      damage_e3: 18474,
      damage_e4: 20271,
      damage_e5: 20271,
      damage_e6: 24176
    },
    time: 14.71,
    total_dmg: 0,
    echo_main: 'Feilian Beringal',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Sierra Gale',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 4,
    name: 'Calcharo',
    slug: 'calcharo',
    disclaimer: '',
    dmg: {
      damage: 389113,
      damage_e1: 389113,
      damage_e2: 392526,
      damage_e3: 431778,
      damage_e4: 465284,
      damage_e5: 470546,
      damage_e6: 514220
    },
    dps: {
      damage: 32671,
      damage_e1: 32671,
      damage_e2: 32958,
      damage_e3: 36253,
      damage_e4: 39067,
      damage_e5: 39509,
      damage_e6: 43176
    },
    time: 11.91,
    total_dmg: 389113,
    echo_main: 'Tempest Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 5,
    name: 'Encore',
    slug: 'encore',
    disclaimer: '',
    dmg: {
      damage: 368173,
      damage_e1: 380654,
      damage_e2: 380654,
      damage_e3: 401392,
      damage_e4: 431470,
      damage_e5: 438194,
      damage_e6: 469068
    },
    dps: {
      damage: 32149,
      damage_e1: 33239,
      damage_e2: 33239,
      damage_e3: 35050,
      damage_e4: 37676,
      damage_e5: 38264,
      damage_e6: 40959
    },
    time: 11.45,
    total_dmg: 368173,
    echo_main: 'Inferno Rider',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 6,
    name: 'Yinlin',
    slug: 'yinlin',
    disclaimer: '',
    dmg: {
      damage: 219498,
      damage_e1: 230182,
      damage_e2: 224123,
      damage_e3: 266713,
      damage_e4: 279783,
      damage_e5: 301864,
      damage_e6: 409628
    },
    dps: {
      damage: 58894,
      damage_e1: 61761,
      damage_e2: 79279,
      damage_e3: 94345,
      damage_e4: 98968,
      damage_e5: 106779,
      damage_e6: 144898
    },
    time: 3.73,
    echo_main: 'Tempest Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats:
      'ATK (36%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (19.2%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 8,
    name: 'Mortefi',
    slug: 'mortefi',
    disclaimer:
      "Mortefi does more damage depending on who he is supporting due to how his ultimate works. His ultimate can fire a maximum amount of 60 coordinated attacks at S0 in a literal perfect world when being abused by a Heavy Attack spammer, but this is not realistic, so we've used 35 coordinated attacks instead. This is how many you could expect to get when using Jiyan. Mortefi's S1 doesn't scale particularly well with Jiyan which is how we've represented but works much much better with characters like Danjin compared to what is shown here.",
    dmg: {
      damage: 128346,
      damage_e1: 135088,
      damage_e2: 135088,
      damage_e3: 140596,
      damage_e4: 195943,
      damage_e5: 203682,
      damage_e6: 214678
    },
    dps: {
      damage: 22837,
      damage_e1: 24037,
      damage_e2: 24037,
      damage_e3: 25017,
      damage_e4: 34865,
      damage_e5: 36242,
      damage_e6: 38199
    },
    time: 5.62,
    total_dmg: 118682,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 9,
    name: 'Aalto',
    slug: 'aalto',
    disclaimer: '',
    dmg: {
      damage: 77167,
      damage_e1: 77167,
      damage_e2: 78784,
      damage_e3: 107518,
      damage_e4: 112202,
      damage_e5: 112202,
      damage_e6: 116757
    },
    dps: {
      damage: 20562,
      damage_e1: 20562,
      damage_e2: 20992,
      damage_e3: 28649,
      damage_e4: 29897,
      damage_e5: 29897,
      damage_e6: 31110
    },
    time: 3.75,
    total_dmg: 110727,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Aero DMG',
    echo_3a: 'Aero DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 10,
    name: 'Lingyang',
    slug: 'lingyang',
    disclaimer: '',
    dmg: {
      damage: 369291,
      damage_e1: 369291,
      damage_e2: 369291,
      damage_e3: 385440,
      damage_e4: 413142,
      damage_e5: 427208,
      damage_e6: 455916
    },
    dps: {
      damage: 27416,
      damage_e1: 27416,
      damage_e2: 27416,
      damage_e3: 28615,
      damage_e4: 30671,
      damage_e5: 31715,
      damage_e6: 33847
    },
    time: 13.47,
    total_dmg: 343072,
    echo_main: 'Mech Abomination',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Lingering Tunes',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 11,
    name: 'Sanhua',
    slug: 'sanhua',
    disclaimer: '',
    dmg: {
      damage: 105093,
      damage_e1: 105093,
      damage_e2: 105093,
      damage_e3: 120138,
      damage_e4: 130650,
      damage_e5: 134876,
      damage_e6: 137058
    },
    dps: {
      damage: 19866,
      damage_e1: 19866,
      damage_e2: 19866,
      damage_e3: 22710,
      damage_e4: 24698,
      damage_e5: 25496,
      damage_e6: 25909
    },
    time: 5.29,
    total_dmg: 107866,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 12,
    name: 'Chixia',
    slug: 'chixia',
    disclaimer:
      "Due to Chixia's reliance on channeled abilities to do damage, achieving this damage output against enemies that move around or force you to dodge is nearly impossible.",
    dmg: {
      damage: 294909,
      damage_e1: 308649,
      damage_e2: 308649,
      damage_e3: 316491,
      damage_e4: 316491,
      damage_e5: 346973,
      damage_e6: 346973
    },
    dps: {
      damage: 30624,
      damage_e1: 32051,
      damage_e2: 32051,
      damage_e3: 32865,
      damage_e4: 32865,
      damage_e5: 36030,
      damage_e6: 36030
    },
    time: 9.63,
    total_dmg: 339243,
    echo_main: 'Inferno Rider',
    echo_4: 'CRIT Rate',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Static Mist',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 13,
    name: 'Rover (Havoc)',
    slug: 'rover-havoc',
    disclaimer: '',
    dmg: {
      damage: 354421,
      damage_e1: 363135,
      damage_e2: 363135,
      damage_e3: 363135,
      damage_e4: 392651,
      damage_e5: 408110,
      damage_e6: 478367
    },
    dps: {
      damage: 31365,
      damage_e1: 32136,
      damage_e2: 32136,
      damage_e3: 32136,
      damage_e4: 34748,
      damage_e5: 36116,
      damage_e6: 42333
    },
    time: 11.3,
    total_dmg: 363772,
    echo_main: 'Dreamless',
    echo_4: 'CRIT DMG',
    echo_3: 'Havoc DMG',
    echo_3a: 'Havoc DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (50%), CRIT Rate (37.5%), CRIT DMG (75%)',
    finalstats: '',
    set_1: 'Havoc Eclipse',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 14,
    name: 'Yuanwu',
    slug: 'yuanwu',
    disclaimer: '',
    dmg: {
      damage: 78577,
      damage_e1: 78577,
      damage_e2: 78577,
      damage_e3: 97299,
      damage_e4: 97299,
      damage_e5: 104036,
      damage_e6: 116277
    },
    dps: {
      damage: 26537,
      damage_e1: 26537,
      damage_e2: 26537,
      damage_e3: 32860,
      damage_e4: 32860,
      damage_e5: 35135,
      damage_e6: 39269
    },
    time: 2.96,
    echo_main: 'Tempest Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'DEF%',
    echo_1a: 'DEF%',
    substats:
      'DEF (34.17%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Amity Accord',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 15,
    name: 'Jinhsi',
    slug: 'jinhsi',
    disclaimer: '',
    dmg: {
      damage: 594378,
      damage_e1: 684289,
      damage_e2: 684289,
      damage_e3: 820103,
      damage_e4: 877992,
      damage_e5: 1039048,
      damage_e6: 1248162
    },
    dps: {
      damage: 43538,
      damage_e1: 50124,
      damage_e2: 50124,
      damage_e3: 60072,
      damage_e4: 64312,
      damage_e5: 76110,
      damage_e6: 91427
    },
    time: 13.65,
    total_dmg: 594378,
    echo_main: 'Jué',
    echo_4: 'CRIT Rate',
    echo_3: 'Spectro DMG',
    echo_3a: 'Spectro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (27%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (28%)',
    finalstats: '',
    set_1: 'Celestial Light',
    set_2: '',
    weapon: 'Lustrous Razor',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 16,
    name: 'Changli',
    slug: 'changli',
    disclaimer: '',
    dmg: {
      damage: 413378,
      damage_e1: 423355,
      damage_e2: 488040,
      damage_e3: 524518,
      damage_e4: 567887,
      damage_e5: 679662,
      damage_e6: 838589
    },
    dps: {
      damage: 39079,
      damage_e1: 40022,
      damage_e2: 46137,
      damage_e3: 49586,
      damage_e4: 53686,
      damage_e5: 64252,
      damage_e6: 79277
    },
    time: 10.58,
    total_dmg: 413378,
    echo_main: 'Inferno Rider',
    echo_4: 'CRIT DMG',
    echo_3: 'Fusion DMG',
    echo_3a: 'Fusion DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%)',
    finalstats: '',
    set_1: 'Molten Rift',
    set_2: '',
    weapon: 'Emerald of Genesis',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 17,
    name: 'Zhezhi',
    slug: 'zhezhi',
    disclaimer: '',
    dmg: {
      damage: 188451,
      damage_e1: 200880,
      damage_e2: 223765,
      damage_e3: 256113,
      damage_e4: 270490,
      damage_e5: 328587,
      damage_e6: 404494
    },
    dps: {
      damage: 26845,
      damage_e1: 28615,
      damage_e2: 31875,
      damage_e3: 36483,
      damage_e4: 38531,
      damage_e5: 46807,
      damage_e6: 57620
    },
    time: 7.02,
    total_dmg: 413378,
    echo_main: 'Impermanence Heron',
    echo_4: 'CRIT Rate',
    echo_3: 'Glacio DMG',
    echo_3a: 'Glacio DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Moonlit Clouds',
    set_2: '',
    weapon: 'Cosmic Ripples',
    weapon_S: 1,
    has_alt_dmg: false
  },
  {
    id: 18,
    name: 'Xiangli Yao',
    slug: 'xiangli-yao',
    disclaimer: '',
    dmg: {
      damage: 422465,
      damage_e1: 479108,
      damage_e2: 525178,
      damage_e3: 597163,
      damage_e4: 632537,
      damage_e5: 780962,
      damage_e6: 983803
    },
    dps: {
      damage: 32207,
      damage_e1: 36526,
      damage_e2: 40038,
      damage_e3: 45526,
      damage_e4: 48223,
      damage_e5: 59538,
      damage_e6: 75002
    },
    time: 13.12,
    total_dmg: 422465,
    echo_main: 'Tempest Mephis',
    echo_4: 'CRIT Rate',
    echo_3: 'Electro DMG',
    echo_3a: 'Electro DMG',
    echo_1: 'ATK%',
    echo_1a: 'ATK%',
    substats: 'ATK (45%), CRIT Rate (42%), CRIT DMG (84%), Energy Regen (9.6%)',
    finalstats: '',
    set_1: 'Void Thunder',
    set_2: '',
    weapon: 'Abyss Surges',
    weapon_S: 1,
    has_alt_dmg: false
  }
];
