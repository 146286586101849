import React from 'react';
import { WWEchoEntry } from '../echoes/ww-echo';
import { WWSet } from '../common/components/ww-set';

interface IProps {
  name: string;
  role: string;
}

export const WWMainEchoesProfile: React.FC<IProps> = ({ name, role }) => {
  return (
    <>
      <div className="build-tips damage">
        {(role === 'Hybrid' || name === 'yangyang' || name === 'jianxin') && (
          <>
            <WWSet name="Moonlit Clouds" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Impermanence Heron"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                Supportive Echo that can be run at the cost of a sizeable chunk
                of damage for the character who equips, trading personal gain
                for team benefits. Should always be used and immediately switch
                cancelled right before performing an Outro, leading to another
                DPS. When done so will grant the next character switching in 12%
                increased DMG% which when combined with the 5P Moonlit set which
                also grants 22.5% ATK% under the same conditions makes for a
                sizeable boost.
              </p>
              <p>
                While the echo does lower the damage of whoever wears it, it
                grants a massive chunk of energy on use usually saving 1 or
                sometimes even 2 Energy Regen sub stats that may otherwise be
                needed by the wearer allowing for some damage to be recovered.
              </p>
            </div>
          </>
        )}
        {(role === 'Support' || name === 'yuanwu') && (
          <>
            <WWSet name="Rejuvenating Glow" mode="min" />
            <div className={`single-item without-notes `}>
              <WWEchoEntry
                name="Bell-Borne Geochelone"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                This Echo is an essential asset that almost every team in the
                game aims to include thanks to its excellent offensive and
                defensive benefits. The reason for this is the excellent 10% DMG
                boost it provides throughout its duration but also 50% damage
                reduction barrier it provides as well. The only catch these
                buffs have is that they only last for 3 hits meaning after
                you've taken them you'll lose the buff until you can re-apply it
                again - even so almost every team aims to have 1 user of this
                echo. Almost always the main echo of choice for users of the 5P
                Rejuvenating Glow set.
              </p>
            </div>
          </>
        )}
        {(name === 'calcharo' ||
          name === 'yinlin' ||
          name === 'yuanwu' ||
          name === 'xiangli-yao') && (
          <>
            <WWSet name="Void Thunder" mode="min" />
            <div className={`single-item`}>
              <WWEchoEntry
                name="Tempest Mephis"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information">
              <p>
                Strong all around choice specifically when using 5P Void
                Thunder. Can be immediately swap cancelled on use for burst
                damage in which the Echo will deal damage equal to 4 tail swipes
                but no claw damage. Alternatively, can be fully cast or swap
                cancelled as soon as the claw attack starts to gain a 12%
                Electro, Heavy DMG boost and more resonance energy at the cost
                of higher field time.
              </p>
              <p>
                Option 1 offers higher echo dps but Option 2 offers a little
                more resonance energy and a buff. Which will be more effective
                for you will depend on playstyle, team and the fight you're up
                against.
              </p>
            </div>
            <div className={`single-item`}>
              <WWEchoEntry
                name="Thundering Mephis"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                Thundering Mephis is most effective when fully used for its
                entire duration. Fully using this Echo demands a tremendous
                amount of field time making it unsuitable for many players,
                teams and situations so only use it if it makes sense for you.
                If you do decide to use it, this echo will generate a very large
                amount of Resonance Energy, deal reasonable total damage (much
                less when compared to a DPS's burst window in similar time) and
                upon the final strike grant a 12% Electro and Liberation DMG%
                bonus.
              </p>
            </div>
            <div className="multiple">
              <WWSet name="Void Thunder" mode="min" />{' '}
              <WWSet name="Lingering Tunes" mode="min" />
            </div>
            <div className={`single-item without-notes`}>
              <WWEchoEntry name="Flautist" level={[5]} mode="icon" onProfile />
            </div>
            <div className="information last">
              <p>
                A more challenging echo to use that requires the user press and
                hold the echo attack button to channel a beam which deals good
                damage, and generates resonance energy and concerto energy. This
                echo can be fully channelled - or if timed just right can be
                swap cancelled - while still yielding most of it's effect. At a
                minimum this echo will deal 3 instances of damage but the longer
                you channel before swap cancelling it the more it will deal. Not
                as easy or popular as either Mephis or Mech Abomination.
              </p>
            </div>
          </>
        )}
        {(name === 'jiyan' || name === 'jianxin') && (
          <>
            <WWSet name="Sierra Gale" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Feilian Beringal"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                One of the best pure DPS echos in the game, capable of dealing
                good damage while also being swap cancelled and still granting
                it's boosting effect. Use this echo in the warm-up phase just
                moments before you plan to enter your Main DPS burst window and
                swap cancel it after it performs its first punch (it will
                continue and use the follow-up even after you switch). After
                that trigger your Hybrid or Supports concerto and then swap back
                to your damage dealer to perform your rotation with the buff
                active.
              </p>
            </div>
          </>
        )}
        {(name === 'encore' || name === 'chixia' || name === 'changli') && (
          <>
            <WWSet name="Molten Rift" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Inferno Rider"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                The Inferno rider deals tremendous cleave damage in a massive
                forward facing arc while also generating a huge amount of
                Resonance Energy and also granting a Fusion and Basic ATK DMG%
                boost, making it a fantastic echo but with a catch.
              </p>
              <p>
                The Inferno Rider's animation is long and while it can be switch
                cancelled, it will take a bit of practice to get just right.
                Your goal when using Inferno Rider is to spam left mouse button
                to perform slashes and switch cancel the echo right after the
                third slash starts. You can observe when this happens by looking
                at the cooldown gauge of the echo (when it reads ~16-18 seconds
                on the dial it's ready). To maximize the benefit of this echo,
                use it and perform the cancel right before your burst combo to
                ensure the buff it grants is present for your upcoming rotation.
              </p>
            </div>
          </>
        )}
        {(name === 'lingyang' || name === 'zhezhi') && (
          <>
            <WWSet name="Freezing Frost" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Lampylumen Myriad"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information">
              <p>
                Easy to use Echo that deals decent total damage in a small AoE,
                has good resonance energy generation and on top of all that
                inflicts a small crowd control freeze effect on hit enemies. The
                gigantic downside of this Echo is that, unlike most other
                powerful echoes, you must fully use its entire effect with no
                good opportunity to perform a switch cancel.
              </p>
              <p>
                This one flaw means you'll be spending close to 3 seconds using
                this Echo each activation - compared to the near instantaneous
                echo activation's of most other elements. In endgame with highly
                invested characters, the time required to make use of this echo
                can actually result in a damage loss in an overall rotation,
                making this echo only recommended in the early game before high
                investment or for its AoE or freezing effects.
              </p>
            </div>
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Glacio Predator"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                Alternative Glacio main echo to Lampylumen Myriad that offers a
                little bit of damage but without demanding any field time at
                all. Thanks to the low cooldown of this echo you can use it
                twice in the time most other echoes can be used once. Not a
                great choice but currently one of the best Glacio has.
              </p>
              <p>
                Cannot be used while airborne (relevant specifically for
                Lingyang burst rotation).
              </p>
            </div>
          </>
        )}
        {(name === 'danjin' || name === 'rover-havoc') && (
          <>
            <WWSet name="Sun-sinking Eclipse" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry name="Dreamless" level={[5]} mode="icon" onProfile />
            </div>
            <div className="information last">
              <p>
                One of the strongest burst damage Echoes in the game that also
                generates a sizeable chunk of Resonance Energy. What makes this
                Echo strong is that its damage is delivered in a massive 360
                degree area around the target it fixates on and is immediately
                swap cancellable the second it's used with 0 downsides. When
                using this Echo, always use it and immediately switch out so it
                deals it's damage and finishes its animation while the caster of
                the Echo is off the field. Due to this fact this Echo is
                normally used outside of characters burst windows.
              </p>
            </div>
          </>
        )}
        {name === 'jinhsi' && (
          <>
            <WWSet name="Celestial Light" mode="min" />
            <div className={`single-item without-notes`}>
              <WWEchoEntry name="Jué" level={[5]} mode="icon" onProfile />
            </div>
            <div className="information">
              <p>
                Jué is Jinhsi's best Echo. Jué deal's tremendous bonus damage
                with 0 field time or swap commitment due to them being a
                summonable Echo instead of transformation one. This gives Jué a
                significant edge compared to all other top Spectro echoes. Jué
                should be used right before Jinhsi's standard burst damage
                rotation as he grants her a 16% Resonance Skill damage bonus for
                15 seconds - with only a 20 seconds cooldown you can have this
                buff up most of the time. Jué also has additional Synergy with
                Jinhsi as their constant DoT damage builds Jinhsi's
                Incandescence, allowing her to gain some extra stacks when she's
                not otherwise satisfying the Spectro trigger for the Forte.
              </p>
            </div>
            <div className={`single-item without-notes`}>
              <WWEchoEntry
                name="Mourning Aix"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
            <div className="information last">
              <p>
                Mourning Aix is an alternative to Jué for Jinhsi but is for the
                most part a straight downgrade across the board unless you
                already have one farmed with exceptional main and sub stats. The
                Aix requires a swap cancel to be implement effectively, does
                less total damage, grants a less desirable spread of DMG% boosts
                and lacks the ability to trigger Jinhsi's Incandescence
                stacking. One advantage of Aix however is that it grants
                slightly more Resonance Energy than Jue, but this is not enough
                to offset all of the disadvantages. When using Mourning Aix use
                it before Jinhsi's burst rotations and swap cancel it to ensure
                its buff is active during your primary damage window.
              </p>
            </div>
          </>
        )}
        {name === 'rover-spectro' && (
          <>
            <WWSet name="Celestial Light" mode="min" />
            <div className={`single-item without-notes last`}>
              <WWEchoEntry
                name="Mourning Aix"
                level={[5]}
                mode="icon"
                onProfile
              />
            </div>
          </>
        )}
        {(role === 'DPS' || name === 'rover-spectro') && (
          <>
            {name != 'rover-havoc' && (
              <>
                <WWSet name="Lingering Tunes" mode="min" />
                <div className={`single-item without-notes`}>
                  <WWEchoEntry
                    name="Mech Abomination"
                    level={[5]}
                    mode="icon"
                    onProfile
                  />
                </div>
                <div className="information last">
                  <p>
                    Very easy echo to use that should be used at the very
                    beginning of the burst window right after the Intro. Thanks
                    to its instant cast there is no thinking required and you
                    can enjoy your ATK% boost and some nice burst damage.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
